'use client'
import { FC } from 'react'
import { useParams, usePathname } from 'next/navigation'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { getTransition, getP40, vw, mq } from '@/styles'

const getTitle = (pathname, _lang) => {
  switch (pathname) {
    case '/':
    case '/[lang]':
      return 'Going back home'
    case '/team-up':
    case '/es/team-up':
      return 'Team up'
    case '/notice':
    case '/es/aviso':
      return 'Legal'
    default:
      return ''
  }
}

export const Cortina:FC = () => {
  const pathname = usePathname()
  const { lang } = useParams()
  const title = getTitle(pathname, lang)

  return (
    <>
      <CortinaOne key={pathname + 'curtainone'} variants={cortina_one_variants} initial='initial' animate='animate' />
      <CortinaTwo key={pathname + 'curtaintwo'} variants={cortina_two_variants} initial='initial' animate='animate' />
      <TransitionText key={pathname + 'text'} variants={text_variants} initial='initial' animate='animate'><h2>{title}</h2></TransitionText>
    </>
  )
}

const CortinaOne = styled(motion.div)`
  bottom: 0;
  background-color: var(--color-text);
  height: 100vh;
  left: 0;
  min-height: 0;
  pointer-events: none;
  position: fixed;
  transform-origin: bottom;
  user-select: none;
  width: 100vw;
  z-index: 1000000000;
`

const CortinaTwo = styled(motion.div)`
  height: 100vh;
  background-color: var(--color-text);
  left: 0;
  min-height: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform-origin: top;
  user-select: none;
  width: 100vw;
  z-index: 1000000000;
  `

const TransitionText = styled(motion.div)`
  align-items: center;
  display: flex;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translateY(-${vw(50, 'mobile')});
  user-select: none;
  width: 100vw;
  z-index: 1000000001;

  ${mq.greaterThan('tablet')} {
    transform: translateY(-${vw(50, 'desktop')});
  }

  h2 {
    ${getP40()}
    color: var(--color-white);
    margin-left: ${vw(20, 'mobile')};
    text-align: left;

    ${mq.greaterThan('tablet')} {
      margin-left: ${vw(30, 'desktop')};
    }
  }
`

const cortina_one_variants = {
  animate: {
    scaleY: [0, 1, 1, 0],
    transition: {
      ...getTransition(),
      duration: 1.3,
      times: [0, 0.4, 0.9, 1]
    }
  }
}

const cortina_two_variants = {
  animate: {
    scaleY: [0, 1, 1, 0],
    transition: {
      ...getTransition(),
      duration: 1,
      delay: 1,
      times: [0, 0.2, 0.6, 1]
    }
  }

}

const text_variants = {
  initial: {
    opacity: 0,
    display: 'flex'
  },
  animate: {
    opacity: [0, 1, 0],
    y: ['100px', '0px', '-100px'],
    transition: {
      ...getTransition(),
      duration: 2,
      times: [0, .5, 1]
    },
    transitionEnd: {
      display: 'none'
    }
  }
}
