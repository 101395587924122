'use client'
import { useMemo, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useSlider, useTranslator } from '@/hooks'
import { getTransition, mq, vw } from '@/styles'

const IMAGES = [
  '/images/contact/img-gatets-1.jpg',
  '/images/contact/img-gatets-2.jpg',
  '/images/contact/img-gatets-3.jpg'
]

const getSlides = (slides, currentIdx) =>  {
  const slide = slides[currentIdx]

  return (
    <motion.figure key={currentIdx} initial='initial' animate='animate' exit='exit' className='slide' variants={imageVariants}>
      <img src={slide} alt='Pet img'/>
    </motion.figure>
  )
}

export const ThemePets = (({ isActive, close, soundEffect }) => {
  const { currentSlide, triggerRef } = useSlider(IMAGES.length, 3500)
  const refAudio = useRef(null)

  const Slides = useMemo(() => getSlides(IMAGES, currentSlide), [currentSlide])
  const { t } = useTranslator()

  useLayoutEffect(() => {
    if (refAudio.current) {
      if (isActive) {
        console.log('soundEffect', soundEffect)
        soundEffect.src = refAudio.current.src
        refAudio.current.play()

      } else {
        refAudio.current.pause()
        refAudio.current.currentTime = 0
      }
    }
  }, [isActive])

  return (
    <Main ref={triggerRef} onClick={close}>
      <AnimatePresence initial={false}>
        {Slides}
      </AnimatePresence>
      <a target='_blank' rel='noreferrer' href='mailto:hola@you.digital' title={t('navbar.contact') as string}>
        <figure>
          <img src='/images/contact/cats-text.svg' width='696' height='353' alt='Email contact icon' />
        </figure>
      </a>
      <audio ref={refAudio}>
        <source src='/sounds/theme-cats.mp3' type='audio/mpeg' />
        <source src='/sounds/theme-cats.mp3' type='audio/ogg' />
      </audio>
    </Main>
  )
})

const Main = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: end;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  figure.slide {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  > a {
    display: block;
    margin: auto;
    z-index: 2;

    figure {
      width: ${vw(374, 'mobile')};
      margin-bottom: ${vw(60, 'mobile')};

      img {
        width: 100%;
        height: 100%;
      }
    }

    figure {
      ${mq.greaterThan('tablet')} {
        width: ${vw(655, 'desktop')};
        height: ${vw(310, 'desktop')};
        margin-bottom: ${vw(44, 'desktop')};

        img {
          filter: drop-shadow( 0px 0px 11px #FFF400);
        }
      }
    }
  }
`

const imageVariants = {
  initial: {
    scale: 1,
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: 1.5,
      delay: .5,
    }
  },
  exit: {
    scale: 1.2,
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: 1.5
    }
  }
}
