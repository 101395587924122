'use client'
import { useMemo, useState } from 'react'
import Link from 'next/link'
import styled     from 'styled-components'
import { motion } from 'framer-motion'
import { vw } from '@/styles'
import { getP40Mobile, getP20Mobile } from '@/styles'
import { useModal, useOverlay } from '@/hooks'
import { MODALS } from '@/context'
import { useParams, useRouter } from 'next/navigation'
import { LOCALE } from '@/utils'

const items = [
  {
    text: 'Home',
    url: '',
    type: 'link'
  },
  {
    text: 'Team up',
    url: 'team-up',
    type: 'link'
  },
  {
    text: 'Talk to us',
    text_es:'Escríbenos',
    type: 'contact'
  }
]

export const MenuMobile = ({ isActive, closeMenu }) => {
  const { push } = useRouter()
  const { lang } = useParams()
  const locale = useMemo(() => lang || LOCALE, [lang])
  const [currentLang, setCurrentLang] = useState(locale)
  const { toggle } = useModal(MODALS.CONTACT)
  const { setIsActive } = useOverlay()

  const onClickChangeLang = (newlang) => {
    setCurrentLang(newlang)
    closeMenu()
    push(`${newlang === 'es' ? '/es' : '/'}`)
  }

  const onClickContact = () => {
    setIsActive(true)
    setTimeout(()=>{
      toggle()
      closeMenu()
      setIsActive(false)
    }, 2500)
  }

  return (
    <Menu animate={isActive ? 'visible' : 'hidden'} initial='hidden' variants={variants}>
      <div className='content-wrapper'>
        <List className='list--nav' animate={isActive ? 'open' : 'closed'} variants={listVariants}>
          {items.map((item, idx) => (
            <ListItem key={idx} variants={listItemVariantsX}>
              {item.type === 'link' &&
                <Link scroll={false} href={`${lang === 'es' ? '/es' : ''}/${item.url}`} onClick={closeMenu}>
                  {item.text}
                </Link>
              }
              {item.type === 'contact' && <button onClick={onClickContact}>{currentLang === 'es'? item.text_es : item.text}</button>}
            </ListItem>
          ))}
        </List>
        <ListLang animate={isActive ? 'open' : 'closed'} variants={listVariants}>
          <ListItem variants={listItemVariantsY} onClick={() => onClickChangeLang('en')} className={(!currentLang || currentLang === 'en') ? 'is-active' : ''}>English</ListItem>
          <ListItem variants={listItemVariantsY} onClick={() => onClickChangeLang('es')} className={currentLang === 'es' ? 'is-active' : ''}>Español</ListItem>
        </ListLang>
      </div>
    </Menu>
  )
}


const Menu = styled(motion.div)`
  background: rgb(22, 24, 30);
  color: white;
  height: auto;
  left: 0;
  min-height: 100vh;
  overflow: hidden;
  padding-top: ${vw(80, 'mobile')};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;

  .content-wrapper {
    max-height: calc(100vh - ${vw(80, 'mobile')});
    overflow-y: auto;
    padding-bottom: ${vw(80, 'mobile')};
    position: relative;
    width: 100%;
  }

  li {
    &,
    a,
    button {
      ${getP40Mobile()};
    }
  }
`

const List = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 6;
  margin-top: ${vw(80, 'mobile')};
  padding: 0 ${vw(17, 'mobile')};

  li {
    margin-bottom: ${vw(10, 'mobile')};
  }
`

const ListLang = styled(motion.ul)`
  display: flex;
  grid-column: 1 / span 6;
  margin-top: ${vw(100, 'mobile')};
  padding: 0 ${vw(17, 'mobile')};

  li {
    margin-right: ${vw(23, 'mobile')};
    color: rgba(255, 255, 255, .2);
    transition: color 300ms ease;

    &,
    a,
    button {
      ${getP20Mobile()}
    }

    &.is-active {
      color: rgba(255, 255, 255, 1);
    }
  }

`

const ListItem = styled(motion.li)`
  grid-column: 1 / span 6;
`

const variants = {
  visible: {
    display: 'block',
    clipPath: 'circle(150% at 92.4% 30px)',
    transition: {
      ease: 'easeIn',
      duration: .3,
      restSpeed: 0.01,
    }
  },
  hidden: {
    clipPath: 'circle(0.1px at 92.4% 30px)',
    transition: {
      ease: 'easeOut',
      delay: .2,
      duration: .2,
    }
  }
}

const listVariants = {
  open: {
    transition: { staggerChildren: .05, delayChildren: .5 }
  },
  closed: {
    transition: { staggerChildren: .05, staggerDirection: -1 }
  }
}

const listItemVariantsX = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 500, velocity: -100 }
    }
  },
  closed: {
    x: 50,
    opacity: 0,
    transition: {
      x: { stiffness: 500 }
    }
  }
}

const listItemVariantsY = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 500, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 500 }
    }
  }
}
