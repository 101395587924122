'use client'
import type { FC, ReactNode } from 'react'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { ThemeProvider } from 'styled-components'
import { getTransition, theme } from '@/styles'
import { TranslationsProvider, ModalsProvider, ProviderVideoReady } from '@/context'
import { usePathname } from 'next/navigation'
import { OverlayProvider } from '@/context/OverlayContext'
import { DivWrapper } from './layout/DivWrapper'
import { Navbar } from './layout/Navbar'
import { Footer } from './layout/Footer'
import { motion, AnimatePresence } from 'framer-motion'
import { Cortina } from './Cortina'

// const DynamicModalMenu = dynamic(() => import('@/components/modals/ModalMenu').then(mod => mod.ModalMenu), { ssr: false })

type Props = {
  children: ReactNode
}

const ClientProviders:FC<Props> = ({ children }) => {
  const pathname = usePathname()

  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <OverlayProvider>
          <TranslationsProvider>
            <ProviderVideoReady value={pathname !== '/' && pathname !== '/team-up'}>
              <ModalsProvider>
                <DivWrapper />
                <Navbar />
                <AnimatePresence initial={false}>
                  <Cortina />
                  <motion.div key={pathname} variants={variants} animate='animate' exit={'exit'} >
                    <AnimatePresence>
                      {children}
                    </AnimatePresence>
                  </motion.div>
                </AnimatePresence>
                {/* <DynamicModalMenu /> */}
                <Footer />
              </ModalsProvider>
            </ProviderVideoReady>
          </TranslationsProvider>
        </OverlayProvider>
      </ThemeProvider>
    </StyledComponentsRegistry>
  )
}

export default ClientProviders


const variants = {
  animate: {
    opacity: [0, 1, 1, 1],
    y: ['100vh', '100vh', '100vh', '0vh'],
    transition: {
      ...getTransition(),
      duration: 2,
      times: [0, 0.2, 0.8, 1]
    }
  },
  exit: {
    y: '-50vh',
    opacity: 0,
    transition: {
      ...getTransition()
    }
  }
}
