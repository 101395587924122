'use client'
import dynamic from 'next/dynamic'
import { useOverlay } from '@/hooks'
import { mq } from '@/styles'
import styled from 'styled-components'
import { type ILottie } from '../Lottie'

const DynamicLottie = dynamic<ILottie>(() => import('../Lottie').then((mod) => mod.Lottie), { ssr: false })

export const DivWrapper = () => {
  const { isActive } = useOverlay()

  if(!isActive) return null

  return (
    <Div>
      <div className='overlay'></div>
      <DynamicLottie name={'carreers-match'} />
    </Div>
  )
}

const Div = styled.div`
 .carreers-match {
    display:block;
		height: auto;
    left:0;
    position: fixed;
    top: 30%;
    width: 100%;
    z-index: 990000;

    ${mq.greaterThan('tablet')} {
      top: 0;
    }
	}

  .overlay{
    background-color: rgba(0,0,0, 0.1);
    backdrop-filter: blur(5px);
    height: 100vh;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transition: all 300ms ease;
    width: 100vw;
    z-index: 20000;

  }
`
