'use client'
import { useState, useMemo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { mq, vw, designGrid, getP20, getTransition } from '@/styles'
import { Logo } from './nav/Logo'
import { useModal, useOverlay, useScrollTracker, useTranslator } from '@/hooks'
import { Burger } from './nav/Burger'
import { MenuMobile } from './nav/MenuMobile'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '../RadixDropdown'
import { MODALS, useVideoReady } from '@/context'
import { useParams, usePathname } from 'next/navigation'
import { LOCALE } from '@/utils'
import { ModalContact } from '../modals/ModalContact'

const langToLocale = {
  'Español' : 'es',
  'English': 'en'
}

const getDropdownItem = (languages, locale, pathname, onClick) => languages.map((language, idx) => {
  if(locale === langToLocale[language]) return null

  const href = pathname.includes('team-up') ? language === 'Español' ? '/es/team-up' : '/team-up' : language === 'Español' ? '/es' : '/'

  return (
    <DropdownMenuItem key={idx}>
      <Link href={href} onClick={onClick}>
        {language}
      </Link>
    </DropdownMenuItem>
  )
})

const ValuesLanguage = {
  'es': 'Español',
  'en': 'English'
}

const DropdownLanguage = () => {
  const pathname = usePathname()
  const { lang } = useParams()
  const { t } = useTranslator()

  const onClick = () => {
    if(!document?.dispatchEvent) return null
    document?.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }))
  }

  const DropdownItems = useMemo(() => getDropdownItem(t('navbar.languages'), lang ?? LOCALE, pathname, onClick), [lang, pathname])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div>{ValuesLanguage[lang as string ?? LOCALE]}</div>
        <FigureStyled><img src='/images/svg/i--dropdown.svg' alt='Icon dropdown arrow' /></FigureStyled>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <motion.div variants={expandVariants} initial={'collapsed'} animate={'expanded'}>
          {DropdownItems}
        </motion.div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const Navbar = () => {
  const scrollState = useScrollTracker(70)
  const { videoReady } = useVideoReady()
  const [menuMobileActive, setMenuMobileActive] = useState(false)
  const { t } = useTranslator()
  const { isActive:isModalActive, toggle } = useModal(MODALS.CONTACT)
  const soundEffect = (typeof Audio !== 'undefined' && new Audio('/sounds/silence.mp3'))
  const pathname = usePathname()
  const { lang } = useParams()
  const { setIsActive } = useOverlay()


  // || scrollState === 'SCROLLING_UP'
  const animate = useMemo(() => scrollState === 'TOP' || menuMobileActive || isModalActive ? 'top' : 'out', [scrollState])

  const playAndOpen = () => {
    setIsActive(true)
    setTimeout(()=>{
      soundEffect.autoplay = true
      toggle()
      setIsActive(false)

    }, 2000)
  }

  return (
    <>
      <ModalContact soundEffect={soundEffect} />
      <Nav $scrollOnTop={scrollState === 'TOP' || menuMobileActive || isModalActive} variants={variantsNav} animate={animate}>
        <AnimatePresence>
          <Link scroll={false} href={lang === 'es' ? '/es' : '/'} passHref>
            <motion.div variants={variants} initial='initial' animate={videoReady ? pathname === '/' ? 'animate2' : pathname === '/team-up' ? 'animate23' : 'animate0' : 'initial'} exit='exit'>
              <span className='srt-only'>Go to the homepage</span>
              <Logo active={menuMobileActive} />
            </motion.div>
          </Link>
        </AnimatePresence>
        <Burger isActive={menuMobileActive} onClick={() => { setMenuMobileActive(!menuMobileActive) }} />
        <AnimatePresence>
          <motion.div className='menu-items' variants={variants} initial='initial' animate={videoReady ? (pathname === '/' || pathname === '/es') ? 'animate2' : (pathname === '/team-up' || pathname === '/es/team-up') ? 'animate23' : 'animate0' : 'initial'} exit='exit'>
            <Link scroll={false} href={lang === 'es' ? '/es/team-up' : '/team-up'}>{t('navbar.careers')}</Link>
            <DropdownLanguage/>
            <button className='contact' onClick={playAndOpen}><img src='/images/svg/i--mail.svg' alt='email icon'></img></button>
          </motion.div>
        </AnimatePresence>
        <MenuMobile closeMenu={() => setMenuMobileActive(false)} isActive={menuMobileActive} />
      </Nav>
    </>
  )
}

const Nav = styled(motion.nav) <{ $scrollOnTop: boolean }>`
  ${designGrid({})}
  align-items: center;
  background-color: var(--color-white);
  height: ${vw(60, 'mobile')};
  position: fixed;
  top: 0;
  transition: 200ms background-color ease-out;
  z-index: 10000;

  ${mq.greaterThan('tablet')} {
    height: ${({ $scrollOnTop }) => vw($scrollOnTop ? 100 : 80, 'desktop')};
  }

  > a {
    z-index: 2000;
  }

  + div {
    min-height: calc(100vh - ${vw(60 + 82, 'mobile')});

    ${mq.greaterThan('tablet')} {
      min-height: calc(100vh - ${vw(100 + 82, 'desktop')});
    }
  }

  .menu-items {
    display: none;

    ${mq.greaterThan('tablet')} {
      align-items: center;
      column-gap: ${vw(40, 'desktop')};
      display: flex;
      grid-column: 7 / span 6;
      justify-content: flex-end;
    }

    a, button {
      ${getP20()}
    }

    .contact {
      img {
        width:24px;
        height:24px;
      }
    }
  }
`

const FigureStyled = styled.figure`
  width: ${vw(6, 'mobile')};

  ${mq.greaterThan('tablet')} {
    width: ${vw(6, 'desktop')};
  }

  img  {
    width: 100%;
  }
`

const variants = {
  initial: {
    y: '-300%',
    transition: {
      ...getTransition(),
    }
  },
  animate2: {
    y: 0,
    transition: {
      ...getTransition(),
      ease: [0.6, 0.2, 0.4, 1.5],
      delay: 2,
    }
  },
  animate23: {
    y: 0,
    transition: {
      ...getTransition(),
      ease: [0.6, 0.2, 0.4, 1.5],
      delay: 2.3,
    }
  },
  animate0: {
    y: 0,
    transition: {
      ...getTransition(),
      ease: [0.6, 0.2, 0.4, 1.5],
    }
  }
}

const variantsNav = {
  top: {
    y: '0%',
    transition: {
      ...getTransition()
    }
  },
  out: {
    y: '-100%',
    transition: {
      ...getTransition()
    }
  },
}

const transition = {
  ease: [.25, .1, .25, 1],
  duration: .3,
  type: 'tween'
}

const expandVariants = {
  expanded: {
    height: 'auto',
    display: 'block',
    opacity: 1,
    transition
  },
  collapsed: {
    height: '0',
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    transition
  }
}
